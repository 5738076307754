module.exports = {
  TIMEZONES: [{
    name: '北京时间',
    id: 1,
    key: 'timezone-beijing',
    value: 'Asia/Shanghai'
  }, {
    name: '伦敦时间',
    id: 2,
    key: 'timezone-london',
    value: 'Europe/London'
  }]
};