import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import settings from './modules/settings';
// eslint-disable-next-line import/no-cycle
import user from './modules/user';
import permission from './modules/permission';
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    permission
  },
  getters
});
export default store;