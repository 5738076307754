// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "rgba(0, 0, 0, 0.65)",
	"menuActiveText": "#34C759",
	"subMenuActiveText": "rgba(0, 0, 0, 0.65)",
	"menuBg": "#FFFFFF",
	"menuHover": "rgba(52, 199, 89, 0.2)",
	"subMenuBg": "#FFFFFF",
	"subMenuHover": "rgba(52, 199, 89, 0.2)",
	"sideBarWidth": "210px"
};
module.exports = exports;
