const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  unreadCount: state => state.app.unreadCount,
  name: state => state.user.name,
  en_name: state => state.user.en_name,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  timezone: state => state.settings.timezone
};
export default getters;