// eslint-disable-next-line import/no-cycle
import request from '@/utils/http';
export function authLogin(data = {}) {
  return request({
    url: '/auth/signin',
    method: 'post',
    data
  });
}
export function authInfo() {
  return request({
    url: '/auth/info',
    method: 'get'
  });
}