import mixins from '@/utils/mixins';
export default {
  name: 'SidebarLogo',
  mixins: [mixins.file, mixins.shop],
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: null // '/static/images/logo.jpg',
    };
  },
  computed: {
    computedTitle() {
      return this.$t('title');
    }
  },
  created() {}
};