var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar",
    staticStyle: {
      "padding-left": "20px"
    }
  }, [_c("breadcrumb", {
    staticClass: "breadcrumb-container"
  }), _c("div", {
    staticClass: "right-menu"
  }, [_c("el-dropdown", {
    staticClass: "avatar-container",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("div", [_vm._v(_vm._s(_vm.$i18n.locale == "zh" ? _vm.name : _vm.en_name))]), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _c("el-dropdown-menu", {
    staticClass: "user-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    nativeOn: {
      click: function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    }
  }, [_vm._v(_vm._s(_vm.$t("login.logout")))])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };