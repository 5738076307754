module.exports = {
  title: process.env.VUE_APP_TITLE || '小野轻煮后台管理系统',
  secret: 'va',
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  timezone: 0
};