import defaultSettings from '@/settings';
const {
  showSettings,
  fixedHeader,
  sidebarLogo
} = defaultSettings;
let timezone = 1;
const localTimezone = localStorage.getItem('timezone');
if (localTimezone != null) {
  timezone = localTimezone;
}
const state = {
  showSettings,
  fixedHeader,
  sidebarLogo,
  timezone
};
const mutations = {
  CHANGE_SETTING: (State, {
    key,
    value
  }) => {
    const stateClone = State;
    stateClone[key] = value;
    if (key === 'timezone') {
      localStorage.setItem('timezone', value);
    }
  }
};
const actions = {
  changeSetting({
    commit
  }, data) {
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};