import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import axios from 'axios';
import { Message } from 'element-ui';
import { getToken } from '@/utils/auth';
import { TIMEZONES } from '@/utils/timezone';
import Vue from 'vue';
import router from '@/router/index';
// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
const removeEmptyProps = obj => {
  if (obj) {
    const newObj = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        newObj[key] = removeEmptyProps(obj[key]); // recurse
      } else if (obj[key] != null) {
        // include undefined
        newObj[key] = obj[key]; // copy value
      }
    });
    return newObj;
  }
  return obj;
};

// create an axios instance
const service = axios.create({
  baseURL: Vue.prototype.$appBaseApi || process.env.VUE_APP_BASE_API,
  timeout: 10000,
  // request timeout
  withCredentials: true,
  transformRequest: [data => removeEmptyProps(data), ...axios.defaults.transformRequest]
});
// request interceptor
service.interceptors.request.use(config => {
  const configClone = config;
  configClone.headers.Authorization = `xyqz ${getToken()}`;
  configClone.headers.TZ = TIMEZONES[window.vue.$store.getters.timezone].value;
  return configClone;
}, error => {
  Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
response => {
  const res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 0) {
    Message({
      message: res.error_message || 'Error',
      type: 'error',
      duration: 3 * 1000
    });
  } else if (res.code === 401) {
    store.dispatch('user/logout').then(() => {
      router.push({
        path: '/login'
      }).catch(() => {});
    });
  }
  return res;
}, error => {
  if (error.response.status === 401) {
    // Message({
    //   message: 'login expired',
    //   type: 'warning',
    //   duration: 2 * 1000,
    // });

    store.dispatch('user/logout').then(() => {
      router.push({
        path: '/login'
      }).catch(() => {});
    });
    return Promise.reject(error);
  }
  // console.log(`err${res.code}`); // for debug
  Message({
    message: error.message,
    type: 'error',
    duration: 3 * 1000
  });
  return Promise.reject(error);
});
export default service;