import { mapGetters } from 'vuex';
import variables from '@/styles/variables.scss';
import Logo from './Logo.vue';
import SidebarItem from './SidebarItem.vue';
export default {
  components: {
    SidebarItem,
    Logo
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar']),
    routes() {
      return this.$router.options.routes;
    },
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return true;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};